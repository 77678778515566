export default {
  SET(state, val) {
    state.list = val;
  },

  UPDATE(state, client) {
    const transIndex = state.list.findIndex((t) => t.id == client.id);
    Object.assign(state.list[transIndex], client);
  },

  REMOVE(state, id) {
    const transIndex = state.list.findIndex((t) => t.id == id);
    state.list.splice(transIndex, 1);
  },
};

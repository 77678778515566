import Vue from "vue";
import axios from "@axios";

export default {
  namespaced: true,
  state: {
    info: {},
    notifications: [],
  },
  getters: {
    fullName: (state) => {
      const { nome, sobrenome } = state.info;
      return `${nome} ${sobrenome}`;
    },
  },
  mutations: {
    SET_ACCOUNT(state, val) {
      state.info = val;
    },
    SET_NOTIFICATIONS(state, val) {
      state.notifications = val;
    },
  },
  actions: {
    fetch({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/users")
          .then((response) => {
            const { data } = response;
            // data.phone = Vue.filter("phoneNumber")(data.phone);
            if (localStorage.getItem("userData") !== null)
              localStorage.removeItem("userData");
            localStorage.setItem("userData", JSON.stringify(data));

            commit("SET_ACCOUNT", data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/account/notifications")
          .then((response) => {
            const { data } = response;

            commit("SET_NOTIFICATIONS", data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateUserInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put("/account/info", data)
          .then((response) => {
            const { data } = response;
            data.phone = Vue.filter("phoneNumber")(data.phone);
            if (localStorage.getItem("userData") !== null)
              localStorage.removeItem("userData");
            localStorage.setItem("userData", JSON.stringify(data));

            commit("SET_ACCOUNT", data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateUserPass(_, data) {
      return new Promise((resolve, reject) => {
        axios
          .put("/account/info/password", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};

import Vue from "vue";
import axios from "@axios";

export default {
  fetch({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/users/list")
        .then((response) => {
          const { data } = response;

          data.map(
            (user) => {
              if (user.telephone)
                user.telephone = Vue.filter("phoneNumber")(user.telephone);
            }
          );

          commit("SET", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  update({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/${user.id}`, user)
        .then((response) => {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  add(_, user) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/users`, user)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  disableEnable({ commit }, user) {
    const updateData = {
      email: user.email,
      deletedAt: user.deletedAt === null ? new Date().toISOString() : null
    };

    return new Promise((resolve, reject) => {
      axios
        .put(`/users/${user.id}`, updateData)
        .then((response) => {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

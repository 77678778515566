import Vue from "vue";
import axios from "@axios";

export default {
  fetch({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/clients/list")
        .then((response) => {
          const { data } = response;

          data.map(
            (client) =>
              (client.telephone = Vue.filter("phoneNumber")(client.telephone))
          );

          commit("SET", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  update({ commit }, client) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/clients/${client.id}`, client)
        .then((response) => {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  add(_, client) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/clients`, client)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  renew({ commit }, client) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/clients/renew/${client.id}`)
        .then((response) => {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

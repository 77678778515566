import axios from "@axios";

export default {
  fetch({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/financies")
        .then((response) => {
          const { data } = response;

          data.map((transaction) => {
            transaction.value = parseFloat(transaction.value);
          });

          commit("SET", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  update({ commit }, financial) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/financies/${financial.id}`, financial)
        .then((response) => {
          commit("UPDATE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  add(_, financial) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/financies`, financial)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/financies`, { data: { id } })
        .then((response) => {
          commit("REMOVE", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

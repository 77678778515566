import Vue from "vue";

export default {
  SET(state, val) {
    state.list = val;
  },

  UPDATE(state, client) {
    const clientIndex = state.list.findIndex((c) => c.id == client.id);
    client.telephone = Vue.filter("phoneNumber")(client.telephone);
    Object.assign(state.list[clientIndex], client);
  },
};
